import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import styles from "./flight-card.module.scss";
import TGIcon from "../../../../../shared/tg-icon";
import moment from "moment";
import {
  clearFlightInfoData,
  disable_continue_btn,
  selected_flightInfo_data,
  flightInfoRequest,
  selected_flight_index,
} from "../../../slice/flightInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import { flightFareRequest } from "../../../slice/flightFareSlice";
import TGPlaceholder from "../../../../../shared/tg-placeholder";
import { RootState } from "../../../slice/RootReducer";
import NoFlightsAvailablePopUp from "../flight-card/no-seats-available-popup";

interface Props {
  iteration: number;
  flightDetails: any;
  hideFlightsParent: any;
  setHideContinueBtnFnc: any;
}

const FlightCard: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const isFlightListLoading = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo.isLoading
  );
  const flightInfoRes = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.flightInfoResponse
  );
  const [cabinClassIndex, setCabinClassIndex] = useState("");
  const [cabinClassName, setCabinClassName] = useState("");
  const [cabinClassColor, setCabinClassColor] = useState("");
  const [cabinClassIcon, setcabinClassIcon] = useState("");
  const [cabinClassParentIndex, setCabinClassParentIndex] = useState("");
  const [hideFlights, setHideFlights] = useState(false);
  const [displaySelectedFlight, setDisplaySelectedFlight] = useState([{}]);

  const [showResults, setShowResults] = useState("");

  const [newResponseData, setNewResponseData] = useState([]);
  const [groupByTrip, setGroupByTrip] = useState<any>({});
  const [isGroupByFlag, setIsGroupByFlag] = useState<boolean>(false);
  const [showNoSeatsAvailablePopup, setShowNoSeatsAvailablePopup] =
    useState(false);
  const [notEnoughMessage, setNotEnoughMessage] = useState(false);

  const handleCloseNoSeats = () => {
    setShowNoSeatsAvailablePopup(false);
  };

  const onClickStopButton = (index: any) =>
    setShowResults(showResults === index ? null : index);

  useEffect(() => {
    const req: any = {
      id: props.iteration,
      info: {
        flightInfo: {
          departureDate: moment(props?.flightDetails?.date).format("DDMMYY"),
          departure: props?.flightDetails?.from?.airportCode,
          arrival: props?.flightDetails?.to?.airportCode,
        },
      },
    };
    setNewResponseData([]);
    dispatch(clearFlightInfoData(req));
    dispatch(flightInfoRequest(req));
  }, [dispatch, props?.flightDetails, props.iteration]);

  useEffect(() => {
    if (flightInfoRes[props?.iteration]?.data?.flightList?.length > 0) {
      const newRes = flightInfoRes[props?.iteration]?.data?.flightList?.map(
        (obj: any) => {
          const cabinClassListArr = [
            {
              bookingClass: "X",
              availability: "0",
              classDesc: "Economy",
            },
            {
              bookingClass: "E",
              availability: "0",
              classDesc: "Premium Economy",
            },
            {
              bookingClass: "I",
              availability: "0",
              classDesc: "Business",
            },
            { bookingClass: "O", availability: "0", classDesc: "First" },
          ];
          let item = JSON.parse(JSON.stringify(obj));
          item["tripType"] = props?.flightDetails?.triptype;
          item.classList.forEach((value: any, key: any) => {
            cabinClassListArr.forEach((val, keys) => {
              if (value.bookingClass === val.bookingClass) {
                cabinClassListArr[keys] = value;
              }
            });
          });
          return {
            ...item,
            classList: cabinClassListArr,
          };
        }
      );
      setNewResponseData(newRes);
    }
  }, [flightInfoRes, props?.flightDetails?.triptype, props?.iteration]);

  // no seats are availablitity and not enough chanegs below here
  const checkAvailability = (j: any, a: any) => {
    let allAvailability = true;
    let lessThanAvailabilty;
    j?.forEach((item: any) => {
      lessThanAvailabilty = item?.classList?.every(
        (clItem: any) => parseInt(clItem?.availability) < a
      );
      if (!lessThanAvailabilty) {
        allAvailability = false;
      }
    });
    if (allAvailability && lessThanAvailabilty) {
      setNotEnoughMessage(true);
      setTimeout(() => {
        if (props?.flightDetails?.journeyType === "Return") {
          if (props?.flightDetails?.triptype === "departure") {
            setShowNoSeatsAvailablePopup(true);
          }
        } else if (props?.flightDetails?.journeyType === "Multi-City") {
          if (props?.flightDetails?.itneraryOrder === 1) {
            setShowNoSeatsAvailablePopup(true);
          } else {
            setShowNoSeatsAvailablePopup(false);
          }
        } else {
          setShowNoSeatsAvailablePopup(true);
        }
      }, 2000);
    }
  };

  useEffect(() => {
    let x = parseInt(props?.flightDetails?.pax);
    let j = newResponseData;
    checkAvailability(j, x);
  }, [newResponseData, props?.flightDetails?.pax]);

  // browser back from review passenger. popupulated selected flight start here
  const selectedFlightIndexData = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.selectedFlightIndex
  );

  const selectedFlightData = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo
  );

  useEffect(() => {
    const groupByTrip =
      selectedFlightIndexData &&
      Object.groupBy(selectedFlightIndexData, (flightObj) => {
        return flightObj?.journeyTypeData?.triptype;
      });
    setGroupByTrip(groupByTrip);
    if (selectedFlightIndexData && Object.keys(groupByTrip).length > 0) {
      setIsGroupByFlag((preValue: any) => !preValue);
    }
  }, []);

  let selectedIndex: any = [];
  // browser back from review passenger. popupulated selected flight ends here
  const handleClassSelect = (
    childIndex: any,
    responseData: any,
    parentIndex: any,
    data: any,
    journeyTypeData: any
  ) => {
    if (data.availability !== "0") {
      // no seats are availablitity and not enough chanegs start here
      let selectedPassengers = journeyTypeData?.pax;
      if (selectedPassengers > parseInt(data?.availability)) {
        setNotEnoughMessage(false);
        setHideFlights(false);
        setShowNoSeatsAvailablePopup(true);
        // no seats are availablitity and not enough chanegs ends here
      } else {
        // browser back from review passenger. popupulated selected flight start here
        if (journeyTypeData?.journeyType === "Multi-City") {
          const filteredFlightData = selectedFlightIndexData.filter((obj) => {
            if (journeyTypeData?.journeyType === "Multi-City") {
              return (
                journeyTypeData.itneraryOrder !==
                obj.journeyTypeData.itneraryOrder
              );
            }
          });
          setGroupByTrip(filteredFlightData);
        } else {
          setGroupByTrip(delete groupByTrip[responseData?.tripType]);
          if (isGroupByFlag) {
            setIsGroupByFlag(false);
          }
        }
        // browser back from review passenger. popupulated selected flight ends here
        if (data.availability !== "0") {
          // new figma changes Return type
          if (journeyTypeData?.journeyType === "Return") {
            if (responseData?.tripType === "arrival") {
              if (isDesktopView) {
                setHideFlights(false);
                props.setHideContinueBtnFnc(true);
              }
            } else if (responseData?.tripType === "departure") {
              if (isDesktopView) {
                setHideFlights(true);
                props.hideFlightsParent(true);
                props.setHideContinueBtnFnc(true);
                const selectedEle = document.getElementById("scrollDepartRef");
                selectedEle?.scrollIntoView({ behavior: "smooth" });
              }
            }
            // Not allowing to next page without selecting depart flight
            if (selectedFlightData?.selectedFlightList?.[0]) {
              props.setHideContinueBtnFnc(true);
              dispatch(disable_continue_btn(false));
            }
          }
          if (journeyTypeData?.journeyType === "Multi-City") {
            // last index for mulicity select
            if (props?.iteration + 1 < journeyTypeData?.routeCount) {
              if (isDesktopView) {
                setIsGroupByFlag(false);
                setHideFlights(true);
                props.hideFlightsParent(true, props?.iteration);
                props.setHideContinueBtnFnc(true);
              }
              const selectedEle = document.getElementById("scrollDepartRef");
              selectedEle?.scrollIntoView({ behavior: "smooth" });
            } else {
              if (isDesktopView) {
                setHideFlights(false);
                setIsGroupByFlag(false);
                dispatch(disable_continue_btn(false));
              }
            }
          }

          if (journeyTypeData?.journeyType === "One-way") {
            if (isDesktopView) {
              setHideFlights(false);
              dispatch(disable_continue_btn(false));
              props.setHideContinueBtnFnc(true);
            }
          }
          setCabinClassIndex((preValue) => (preValue = childIndex));
          setCabinClassParentIndex((preValue) => (preValue = parentIndex));
          setCabinClassName((preValue) => (preValue = data.bookingClass));
          // browser back from review passenger. popupulated selected flight start here
          setCabinClassColor(
            (preValue) => (preValue = getCabinClassColor(data.bookingClass))
          );
          setcabinClassIcon(
            (preValue) => (preValue = getIconColor(data.bookingClass))
          );
          const cabinClassColor = getCabinClassColor(data.bookingClass);
          const cabinClassIconColor = getIconColor(data.bookingClass);
          selectedIndex.push({
            parentIndex,
            childIndex,
            data,
            cabinClassColor,
            journeyTypeData,
            cabinClassIconColor,
          });

          const filteredFlightData = selectedFlightIndexData.filter((obj) => {
            if (journeyTypeData?.journeyType === "Multi-City") {
              return (
                journeyTypeData.itneraryOrder !==
                obj.journeyTypeData.itneraryOrder
              );
            } else {
              return obj.journeyTypeData.triptype !== responseData.tripType;
            }
          });
          dispatch(
            selected_flight_index([...filteredFlightData, ...selectedIndex])
          );
          // browser back from review passenger. popupulated selected flight ends here

          // passing selected flight and class info to summary
          const selectedFlightInfo = [
            {
              selectedFlight: responseData,
              bookingClass: data,
              airportLocations: journeyTypeData,
            },
          ];
          setDisplaySelectedFlight(selectedFlightInfo);

          //starts here for passing values to routelisting page for selected flight list values
          if (journeyTypeData?.journeyType === "Return") {
            if (responseData?.tripType === "arrival") {
              window.localStorage.removeItem("arrival");
              window.localStorage.setItem(
                "arrival",
                JSON.stringify(selectedFlightInfo)
              );
            } else if (responseData?.tripType === "departure") {
              window.localStorage.removeItem("departure");
              window.localStorage.setItem(
                "departure",
                JSON.stringify(selectedFlightInfo)
              );
            }
          } else if (journeyTypeData?.journeyType === "Multi-City") {
            window.localStorage.removeItem("departure");
            window.localStorage.removeItem("arrival");
            window.localStorage.removeItem("flight" + props.iteration);
            window.localStorage.setItem(
              "flight" + props.iteration,
              JSON.stringify(selectedFlightInfo)
            );
          } else if (journeyTypeData?.journeyType === "One-way") {
            window.localStorage.removeItem("departure");
            window.localStorage.removeItem("arrival");
            window.localStorage.setItem(
              "departure",
              JSON.stringify(selectedFlightInfo)
            );
          }
          //ends here for passing values to routelisting page for selected flight list values
          dispatch(selected_flightInfo_data(selectedFlightInfo));
          getFareWithoutPnr(selectedFlightInfo);
        } else {
          return false;
        }
      }
    }
  };
  // browser back from review passenger. popupulated selected flight start here
  const getCabinColorByBackBtn = (
    responseData: any,
    parentIndex: any,
    childIndex: any,
    data: any,
    journeyTypeData: any
  ) => {
    if (isGroupByFlag) {
      if (journeyTypeData?.journeyType !== "Multi-City") {
        const currentFlightIndexInfo =
          !!groupByTrip[responseData.tripType] &&
          groupByTrip[responseData.tripType][0];
        return !!groupByTrip &&
          currentFlightIndexInfo &&
          currentFlightIndexInfo["childIndex"] === childIndex &&
          currentFlightIndexInfo["parentIndex"] === parentIndex &&
          currentFlightIndexInfo.data.bookingClass === data.bookingClass
          ? currentFlightIndexInfo.cabinClassColor
          : "serviceClassColor";
      } else {
        const cabinColor =
          !!groupByTrip[responseData.tripType] &&
          groupByTrip[responseData.tripType]?.filter(
            (currentFlightIndexInfo: any) => {
              if (
                currentFlightIndexInfo &&
                currentFlightIndexInfo["journeyTypeData"].itneraryOrder ===
                  journeyTypeData?.itneraryOrder &&
                currentFlightIndexInfo["childIndex"] === childIndex &&
                currentFlightIndexInfo["parentIndex"] === parentIndex &&
                currentFlightIndexInfo.data.bookingClass === data.bookingClass
              ) {
                return currentFlightIndexInfo;
              }
            }
          );
        return cabinColor.length > 0
          ? cabinColor[0]["cabinClassColor"]
          : "serviceClassColor";
      }
    }
  };

  const getCabinIconByBackBtn = (
    responseData: any,
    parentIndex: any,
    childIndex: any,
    data: any,
    journeyTypeData: any
  ) => {
    if (isGroupByFlag) {
      if (journeyTypeData?.journeyType !== "Multi-City") {
        const currentFlightIndexInfo =
          !!groupByTrip[responseData.tripType] &&
          groupByTrip[responseData.tripType][0];
        return !!groupByTrip &&
          currentFlightIndexInfo &&
          currentFlightIndexInfo["childIndex"] === childIndex &&
          currentFlightIndexInfo["parentIndex"] === parentIndex &&
          currentFlightIndexInfo.data.bookingClass === data.bookingClass
          ? currentFlightIndexInfo.cabinClassIconColor
          : "#FFF";
      } else {
        const cabinColor =
          !!groupByTrip[responseData.tripType] &&
          groupByTrip[responseData.tripType]?.filter(
            (currentFlightIndexInfo: any) => {
              if (
                currentFlightIndexInfo &&
                currentFlightIndexInfo["journeyTypeData"].itneraryOrder ===
                  journeyTypeData?.itneraryOrder &&
                currentFlightIndexInfo["childIndex"] === childIndex &&
                currentFlightIndexInfo["parentIndex"] === parentIndex &&
                currentFlightIndexInfo.data.bookingClass === data.bookingClass
              ) {
                return currentFlightIndexInfo;
              }
            }
          );
        return cabinColor.length > 0
          ? cabinColor[0]["cabinClassIconColor"]
          : "#FFF";
      }
    }
  };

  // browser back from review passenger. popupulated selected flight ends here
  const showSelectedFlight = () => {
    setHideFlights(false);
    props.hideFlightsParent(false, props?.iteration);
    setIsGroupByFlag(false);
  };

  const getFareWithoutPnr = (selectedFlightInfo: any[]) => {
    let inputFlightInfo: {
      departureDate: string;
      origin: string;
      destination: string;
      flightNumber: string;
      bookingClass: string;
    }[] = [];
    selectedFlightInfo.forEach((value, key) => {
      let flightDetail = {
        departureDate: "",
        origin: "",
        destination: "",
        flightNumber: "",
        bookingClass: "",
      };

      flightDetail.departureDate = value.selectedFlight.departureDate;
      flightDetail.origin = value.selectedFlight.departure;
      flightDetail.destination = value.selectedFlight.arrival;
      flightDetail.flightNumber = value.selectedFlight.flightNum;
      flightDetail.bookingClass = value.bookingClass.bookingClass;
      inputFlightInfo.push(flightDetail);
    });
    const reqData: any = {
      paxInfo: [
        {
          groupId: 1,
          numberOfPax: parseInt(props?.flightDetails?.pax),
        },
      ],
      flightInfo: inputFlightInfo,
    };
    dispatch(flightFareRequest(reqData));
  };

  const getIconColor = (bookingClass: string) => {
    return bookingClass === "X"
      ? "#B6419C"
      : bookingClass === "E"
      ? "#684B9B"
      : bookingClass === "I"
      ? "#684B9B"
      : bookingClass === "O"
      ? "#857030"
      : "#FFF";
  };

  const getCabinClassColor = (bookingClass: string) => {
    return bookingClass === "X"
      ? "ecoColor"
      : bookingClass === "E"
      ? "ecoPlusColor"
      : bookingClass === "I"
      ? "businessColor"
      : bookingClass === "O"
      ? "firstClassColor"
      : "serviceClassColor";
  };

  function NoFlightsAvailable() {
    return (
      <div className={styles.noFlightsDiv}>
        <div className={styles.noFlightsCardDiv}>
          <div className={styles.noFlightsImgAndContent}>
            <TGIcon icon="no-flights-available" size={""} fillColor={""} />
            <div className={styles.noFlightsAndChangeFlightDiv}>
              <span className={styles.noAvailableLabel}>
                {t("lable_no_flights_available")}
              </span>
              <span className={styles.changeSelectedDateLabel}>
                {t("lable_please_change_selected_date")}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`flightList global-content-padding ${styles.flightCardListContainer}`}
      >
        {flightInfoRes[props?.iteration]?.data?.Code !== 200 && (
          <div className={styles.flightList}>
            <span className={styles.flightDetailsText}>
              {flightInfoRes[props?.iteration]?.data?.Message}
            </span>
          </div>
        )}
        {isFlightListLoading ? (
          <TGPlaceholder
            typeClass="p"
            animationVariant="wave"
            variant="text"
            sizeClass={12}
            numberOfLines={4}
            loaderStyleClass=""
          />
        ) : (
          <>
            {flightInfoRes[props?.iteration]?.data?.flightList?.length > 0 ? (
              <>
                {newResponseData?.map((responseData: any, parentIndex: any) => (
                  <div>
                    {!hideFlights ? (
                      <div key={parentIndex} className={styles.flightList}>
                        <div className={styles.flightCardList}>
                          <div className={styles.redeemOrgnDestn}>
                            <div className={styles.flightTripsView}>
                              <div className={styles.flightListOriginDestnView}>
                                <div className={styles.flightDepartArrival}>
                                  <div className={styles.timeAndDate}>
                                    <span className={styles.timeLabel}>
                                      {moment(
                                        responseData?.departureTime,
                                        "HHmm"
                                      ).format("hh:mm")}
                                    </span>
                                    <span className={styles.dateLabel}>
                                      {moment(
                                        responseData?.departureDate,
                                        "DDMMYY"
                                      ).format("ddd, DD MMM YYYY")}
                                    </span>
                                  </div>

                                  <div className={styles.airportCodeAndName}>
                                    <span className={styles.airportCode}>
                                      {responseData?.departure}
                                    </span>

                                    <span className={styles.airportName}>
                                      {props.flightDetails?.from?.cityName}
                                    </span>
                                  </div>

                                  <div className={styles.airportAndTerminal}>
                                    <span className={styles.airportLabel}>
                                      {props.flightDetails?.from?.airportName}
                                    </span>
                                    <span className={styles.terminalLabel}>
                                      {t("label_redemption_termainal")}{" "}
                                      {responseData?.departureTerminal || "-"}
                                    </span>
                                  </div>
                                </div>
                                <div className={styles.flightMiddleIcon}>
                                  <div className={styles.flightMiddleIconView}>
                                    <span className={styles.hoursAndMin}>
                                      {moment
                                        .utc(
                                          moment(
                                            responseData?.arrivalTime,
                                            "HHmm"
                                          ).diff(
                                            moment(
                                              responseData?.departureTime,
                                              "HHmm"
                                            )
                                          )
                                        )
                                        .format("h [h] m [min]")}
                                    </span>
                                    <span className={styles.flightIconView}>
                                      <hr
                                        className={styles.horizontalLine}
                                      ></hr>
                                      <TGIcon
                                        icon="plane-icon"
                                        size={""}
                                        fillColor={""}
                                      />
                                      <hr
                                        className={styles.horizontalLine}
                                      ></hr>
                                    </span>
                                    {responseData.numOfStops >= 1 ? (
                                      <button
                                        className={`${styles.stopBtn}`}
                                        onClick={() =>
                                          onClickStopButton(parentIndex)
                                        }
                                      >
                                        <span className={styles.nonstop}>
                                          1 {t("label_redemption_stop")}
                                        </span>
                                        {showResults === parentIndex ? (
                                          <TGIcon
                                            icon="up-arrow-icon"
                                            size={""}
                                            fillColor={"none"}
                                          />
                                        ) : (
                                          <TGIcon
                                            icon="down-arrow-icon"
                                            size={""}
                                            fillColor={"none"}
                                          />
                                        )}
                                      </button>
                                    ) : (
                                      <button className={styles.nonstopButton}>
                                        <span className={styles.nonstop}>
                                          {t("label_nonstop")}
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className={styles.flightDepartArrival}>
                                  <div className={styles.timeAndDate}>
                                    <span className={styles.timeLabel}>
                                      {moment(
                                        responseData?.arrivalTime,
                                        "HHmm"
                                      ).format("hh:mm")}
                                    </span>
                                    <span className={styles.dateLabel}>
                                      {moment(
                                        responseData?.arrivalDate,
                                        "DDMMYY"
                                      ).format("ddd, DD MMM YYYY")}
                                    </span>
                                  </div>

                                  <div className={styles.airportCodeAndName}>
                                    <span className={styles.airportCode}>
                                      {responseData.arrival}
                                    </span>
                                    <span className={styles.airportName}>
                                      {props.flightDetails?.to?.cityName}
                                    </span>
                                  </div>

                                  <div className={styles.airportAndTerminal}>
                                    <span className={styles.airportLabel}>
                                      {props.flightDetails?.to?.airportName}
                                    </span>
                                    <span className={styles.terminalLabel}>
                                      {t("label_redemption_termainal")}{" "}
                                      {responseData?.arrivalTerminal}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className={styles.logoThai}>
                                <span className={styles.flightIcon}>
                                  <div className={`d-flex ${styles.logoIcon}`}>
                                    <TGIcon
                                      icon="thai-header-logo"
                                      fillColor=""
                                      size="10px"
                                    />
                                    <TGIcon
                                      icon="thai-header-text"
                                      fillColor=""
                                      size="18px"
                                    />
                                  </div>
                                  <span className={styles.flightCode}>
                                    {responseData?.mc} {responseData?.flightNum}
                                  </span>
                                </span>
                                <span className={styles.flightNameCode}>
                                  {responseData?.aircraftTypeDesc}
                                </span>
                              </div>
                            </div>
                            {isDesktopView && (
                              <div className={styles.classListContainer}>
                                {responseData.classList?.map(
                                  (data: any, childIndex: any) => (
                                    <div
                                      key={childIndex}
                                      className="classListDiv"
                                    >
                                      {/* // browser back from review passenger. popupulated selected flight start here */}
                                      <div
                                        id={
                                          responseData?.tripType === "departure"
                                            ? "scrollDepartRef"
                                            : "scrollReturnRef"
                                        }
                                        className={
                                          data.availability === "0"
                                            ? "disabledServiceClass"
                                            : !isGroupByFlag
                                            ? cabinClassIndex === childIndex &&
                                              cabinClassParentIndex ===
                                                parentIndex &&
                                              cabinClassName ===
                                                data.bookingClass
                                              ? cabinClassColor
                                              : "serviceClassColor"
                                            : getCabinColorByBackBtn(
                                                responseData,
                                                parentIndex,
                                                childIndex,
                                                data,
                                                props.flightDetails
                                              )
                                        }
                                        key={data.id}
                                        onClick={() =>
                                          handleClassSelect(
                                            childIndex,
                                            responseData,
                                            parentIndex,
                                            data,
                                            props.flightDetails
                                          )
                                        }
                                      >
                                        <div className={styles.serviceClassDiv}>
                                          {data.availability === "0" ? (
                                            <div
                                              className={
                                                styles.normalCirlceIcon
                                              }
                                            ></div>
                                          ) : (
                                            <div>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                              >
                                                <rect
                                                  width="16"
                                                  height="16"
                                                  rx="8"
                                                  fill="#DFE0EB"
                                                />
                                                <circle
                                                  cx="8"
                                                  cy="8"
                                                  r="5"
                                                  fill={
                                                    !isGroupByFlag
                                                      ? cabinClassIndex ===
                                                          childIndex &&
                                                        cabinClassName ===
                                                          data.bookingClass &&
                                                        cabinClassParentIndex ===
                                                          parentIndex
                                                        ? cabinClassIcon
                                                        : "#FFF"
                                                      : getCabinIconByBackBtn(
                                                          responseData,
                                                          parentIndex,
                                                          childIndex,
                                                          data,
                                                          props.flightDetails
                                                        )
                                                  }
                                                />
                                              </svg>
                                            </div>
                                            // browser back from review passenger. popupulated selected flight ends here
                                          )}
                                          <div
                                            className={
                                              data.availability === "0"
                                                ? `${styles["disabledTextLabel"]}`
                                                : `${styles["classListLabels"]}`
                                            }
                                          >
                                            <span>{data.classDesc}</span>
                                            <br></br>
                                          </div>
                                        </div>

                                        {data.availability === "0" ? (
                                          <div
                                            className={
                                              styles.notAvailableRedeem
                                            }
                                          >
                                            {isDesktopView ? (
                                              <span>
                                                {t(
                                                  "label_redemption_flight_class_not_available"
                                                )}
                                              </span>
                                            ) : null}
                                          </div>
                                        ) : (
                                          <div className={styles.amtMiles}>
                                            <>
                                              {props.flightDetails
                                                .journeyType !==
                                              "Multi-City" ? (
                                                <>
                                                  <span
                                                    className={styles.amount}
                                                  >
                                                    {data.miles}{" "}
                                                  </span>
                                                  <span
                                                    className={
                                                      styles.milesLabel
                                                    }
                                                  >
                                                    {t(
                                                      "label_redemption_header_miles"
                                                    )}
                                                  </span>
                                                </>
                                              ) : null}
                                            </>
                                            {isDesktopView ? (
                                              <span
                                                className={styles.noOfSeats}
                                              >
                                                {data.availability}{" "}
                                                {t(
                                                  "label_redemption_flight_class_seats_left"
                                                )}
                                              </span>
                                            ) : null}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {displaySelectedFlight &&
                        displaySelectedFlight.length > parentIndex
                          ? displaySelectedFlight.map(
                              (responseData: any, index: any) => (
                                <div key={index} className={styles.flightList}>
                                  <div className={styles.flightCardList}>
                                    <div
                                      className={
                                        styles.redeemOrgnDestnSelectedFlight
                                      }
                                    >
                                      <div className={styles.flightTripsView}>
                                        <div
                                          className={
                                            styles.flightListOriginDestnView
                                          }
                                        >
                                          <div
                                            className={
                                              styles.flightDepartArrival
                                            }
                                          >
                                            <div className={styles.timeAndDate}>
                                              <span
                                                className={styles.timeLabel}
                                              >
                                                {moment(
                                                  responseData?.selectedFlight
                                                    ?.departureTime,
                                                  "HHmm"
                                                ).format("hh:mm")}
                                              </span>
                                              <span
                                                className={styles.dateLabel}
                                              >
                                                {moment(
                                                  responseData?.selectedFlight
                                                    ?.departureDate,
                                                  "DDMMYY"
                                                ).format("ddd, DD MMM YYYY")}
                                              </span>
                                            </div>

                                            <div
                                              className={
                                                styles.airportCodeAndName
                                              }
                                            >
                                              <span
                                                className={styles.airportCode}
                                              >
                                                {
                                                  responseData?.selectedFlight
                                                    ?.departure
                                                }
                                              </span>

                                              <span
                                                className={styles.airportName}
                                              >
                                                {
                                                  props.flightDetails?.from
                                                    ?.cityName
                                                }
                                              </span>
                                            </div>

                                            <div
                                              className={
                                                styles.airportAndTerminal
                                              }
                                            >
                                              <span
                                                className={styles.airportLabel}
                                              >
                                                {
                                                  props.flightDetails?.from
                                                    ?.airportName
                                                }
                                              </span>
                                              <span
                                                className={styles.terminalLabel}
                                              >
                                                {t(
                                                  "label_redemption_termainal"
                                                )}{" "}
                                                {
                                                  responseData?.selectedFlight
                                                    ?.departureTerminal
                                                }
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            className={styles.flightMiddleIcon}
                                          >
                                            <div
                                              className={
                                                styles.flightMiddleIconView
                                              }
                                            >
                                              <span
                                                className={styles.hoursAndMin}
                                              >
                                                {moment
                                                  .utc(
                                                    moment(
                                                      responseData
                                                        ?.selectedFlight
                                                        ?.arrivalTime,
                                                      "HHmm"
                                                    ).diff(
                                                      moment(
                                                        responseData
                                                          ?.selectedFlight
                                                          ?.departureTime,
                                                        "HHmm"
                                                      )
                                                    )
                                                  )
                                                  .format("h [h] m [min]")}
                                              </span>
                                              <span
                                                className={
                                                  styles.flightIconView
                                                }
                                              >
                                                <hr
                                                  className={
                                                    styles.horizontalLine
                                                  }
                                                ></hr>
                                                <TGIcon
                                                  icon="plane-icon"
                                                  size={""}
                                                  fillColor={""}
                                                />
                                                <hr
                                                  className={
                                                    styles.horizontalLine
                                                  }
                                                ></hr>
                                              </span>
                                              {responseData?.selectedFlight
                                                ?.numOfStops >= 1 ? (
                                                <button
                                                  className={`${styles.stopBtn}`}
                                                  onClick={() =>
                                                    onClickStopButton(
                                                      parentIndex
                                                    )
                                                  }
                                                >
                                                  <span
                                                    className={styles.nonstop}
                                                  >
                                                    1{" "}
                                                    {t("label_redemption_stop")}
                                                  </span>
                                                  {showResults ===
                                                  parentIndex ? (
                                                    <TGIcon
                                                      icon="up-arrow-icon"
                                                      size={""}
                                                      fillColor={"none"}
                                                    />
                                                  ) : (
                                                    <TGIcon
                                                      icon="down-arrow-icon"
                                                      size={""}
                                                      fillColor={"none"}
                                                    />
                                                  )}
                                                </button>
                                              ) : (
                                                <button
                                                  className={
                                                    styles.nonstopButton
                                                  }
                                                >
                                                  <span
                                                    className={styles.nonstop}
                                                  >
                                                    {t("label_nonstop")}
                                                  </span>
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            className={
                                              styles.flightDepartArrival
                                            }
                                          >
                                            <div className={styles.timeAndDate}>
                                              <span
                                                className={styles.timeLabel}
                                              >
                                                {moment(
                                                  responseData?.selectedFlight
                                                    ?.arrivalTime,
                                                  "HHmm"
                                                ).format("hh:mm")}
                                              </span>
                                              <span
                                                className={styles.dateLabel}
                                              >
                                                {moment(
                                                  responseData?.selectedFlight
                                                    ?.arrivalDate,
                                                  "DDMMYY"
                                                ).format("ddd, DD MMM YYYY")}
                                              </span>
                                            </div>

                                            <div
                                              className={
                                                styles.airportCodeAndName
                                              }
                                            >
                                              <span
                                                className={styles.airportCode}
                                              >
                                                {
                                                  responseData?.selectedFlight
                                                    ?.arrival
                                                }
                                              </span>
                                              <span
                                                className={styles.airportName}
                                              >
                                                {
                                                  props.flightDetails?.to
                                                    ?.cityName
                                                }
                                              </span>
                                            </div>

                                            <div
                                              className={
                                                styles.airportAndTerminal
                                              }
                                            >
                                              <span
                                                className={styles.airportLabel}
                                              >
                                                {
                                                  props.flightDetails?.to
                                                    ?.airportName
                                                }
                                              </span>
                                              <span
                                                className={styles.terminalLabel}
                                              >
                                                {t(
                                                  "label_redemption_termainal"
                                                )}{" "}
                                                {
                                                  responseData?.selectedFlight
                                                    ?.arrivalTerminal
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className={
                                            styles.logoThaiSelectedFlight
                                          }
                                        >
                                          <span className={styles.flightIcon}>
                                            <div
                                              className={`d-flex ${styles.logoIcon}`}
                                            >
                                              <TGIcon
                                                icon="review-info-thai-logo"
                                                fillColor=""
                                                size="10px"
                                              />
                                              <TGIcon
                                                icon="review-info-thai-logo-text"
                                                fillColor=""
                                                size="10px"
                                              />
                                            </div>
                                            <span className={styles.flightCode}>
                                              {responseData?.selectedFlight?.mc}{" "}
                                              {
                                                responseData?.selectedFlight
                                                  ?.flightNum
                                              }
                                            </span>
                                          </span>
                                          <span
                                            className={styles.flightNameCode}
                                          >
                                            {
                                              responseData?.selectedFlight
                                                ?.aircraftTypeDesc
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className={styles.classListContainer}
                                      >
                                        <div className={styles.selectedClasses}>
                                          <div
                                            className={styles.selectedClassDiv}
                                          >
                                            <span
                                              className={
                                                styles.displayClassesData
                                              }
                                            >
                                              {
                                                responseData?.bookingClass
                                                  ?.classDesc
                                              }
                                            </span>
                                          </div>
                                          <div className={styles.changesBtnDiv}>
                                            <button
                                              className={styles.changeBtn}
                                              onClick={showSelectedFlight}
                                            >
                                              <span
                                                className={
                                                  styles.changeTextLabel
                                                }
                                              >
                                                {t(
                                                  "label_redemption_edit_flight"
                                                )}
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          : null}
                      </>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <NoFlightsAvailable />
            )}
          </>
        )}
        {/* no seats are availablitity and not enough chanegs below here */}
        {showNoSeatsAvailablePopup && (
          <NoFlightsAvailablePopUp
            hidePanel={handleCloseNoSeats}
            notEnoughMessage={notEnoughMessage}
          />
        )}
      </div>
    </>
  );
};

export default FlightCard;
