import { useState, useEffect } from "react";
import TGModal from "../../../../../../../shared/tg-modal";
import TGIcon from "../../../../../../../shared/tg-icon";
import styles from "./flight-details.module.scss";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface IProps {
  hidePanel: () => void;
  flightDetailsList?: any;
  flightDetailsSummary: any;
}
const FlightDetails = (props: IProps) => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (show) {
      document.documentElement.style.setProperty("--body-overflow", "hidden");
    } else {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    }
    return () => {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };
  }, [show]);

  return (
    <>
      <TGModal
        show={show}
        handleClose={props.hidePanel}
        buttonLabel="OK"
        customOffCanvasClass={styles.flightDetailCanvasClass}
        centered={true}
        backdrop="static"
        heading="Flight Details"
        headingClass="flightDetailHeading"
      >
        <div className={styles.modelBody}>
          <div className={styles.modelBodyContent}>
            <div className={styles.travellingLine}>
              <TGIcon icon="travel-icon" fillColor="" />
            </div>
            {props.flightDetailsList !== undefined && (
              <div className={styles.flightDetailsContent}>
                <div className={styles.fromCityContainer}>
                  <div className={styles.airportTimeName}>
                    <div className={styles.timeContainer}>
                      <span className={styles.time}>
                        {moment(
                          props?.flightDetailsList?.responseData?.departureTime,
                          "HHmm"
                        ).format("hh:mm")}
                      </span>
                    </div>
                    <div className={styles.airportName}>
                      {props?.flightDetailsList?.flightDetails?.flightDetails
                        ?.from?.cityName +
                        "(" +
                        props?.flightDetailsList?.responseData?.departure +
                        ")"}
                    </div>
                  </div>
                  <div className={styles.subAirportContainer}>
                    <span className={styles.subAirportName}>
                      {
                        props?.flightDetailsList?.flightDetails?.flightDetails
                          ?.from?.airportName
                      }
                    </span>
                    <div className={styles.terminalContainer}>
                      <TGIcon icon="circle-icon" fillColor="" />
                      <span className={styles.terminalText}>
                        {t("label_redemption_termainal")}{" "}
                        {props?.flightDetailsList?.responseData
                          ?.departureTerminal || "-"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.hoursContainer}>
                  {moment
                    .utc(
                      moment(
                        props?.flightDetailsList?.responseData?.arrivalTime,
                        "HHmm"
                      ).diff(
                        moment(
                          props?.flightDetailsList?.responseData?.departureTime,
                          "HHmm"
                        )
                      )
                    )
                    .format("h [h] m [min]")}
                </div>
                <div className={styles.toCityContainer}>
                  <div className={styles.airportTimeName}>
                    <div className={styles.timeContainer}>
                      <span className={styles.time}>
                        {moment(
                          props?.flightDetailsList?.responseData?.arrivalTime,
                          "HHmm"
                        ).format("hh:mm")}
                      </span>
                    </div>
                    <div className={styles.airportName}>
                      {props?.flightDetailsList?.flightDetails?.flightDetails
                        ?.to?.cityName +
                        "(" +
                        props?.flightDetailsList?.responseData?.arrival +
                        ")"}
                    </div>
                  </div>
                  <div className={styles.subAirportContainer}>
                    <span className={styles.subAirportName}>
                      {
                        props?.flightDetailsList?.flightDetails?.flightDetails
                          ?.to?.airportName
                      }
                    </span>
                    <div className={styles.terminalContainer}>
                      <TGIcon icon="circle-icon" fillColor="" />
                      <span className={styles.terminalText}>
                        {t("label_redemption_termainal")}{" "}
                        {props?.flightDetailsList?.responseData
                          ?.arrivalTerminal || "-"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.airCraftContainer}>
                  <div className={styles.aircraftHeader}>
                    <TGIcon icon="star-alliance-icon" fillColor="" />
                    <span className={styles.thaiAirwaysText}>
                      {t("label_redemption_thai_airways_radio")}
                    </span>
                    <TGIcon icon="circle-icon" fillColor="" />
                    <span className={styles.tgFlightNumber}>
                      {props?.flightDetailsList?.responseData?.mc}
                      {props?.flightDetailsList?.responseData?.flightNum}
                    </span>
                  </div>
                  <div className={styles.airbusName}>
                    {props?.flightDetailsList?.responseData?.aircraftTypeDesc}
                  </div>
                </div>
              </div>
            )}
            {props.flightDetailsSummary !== undefined && (
              <div className={styles.flightDetailsContent}>
                <div className={styles.fromCityContainer}>
                  <div className={styles.airportTimeName}>
                    <div className={styles.timeContainer}>
                      <span className={styles.time}>
                        {moment(
                          props?.flightDetailsSummary?.responseData
                            ?.selectedFlight?.departureTime,
                          "HHmm"
                        ).format("hh:mm")}
                      </span>
                    </div>
                    <div className={styles.airportName}>
                      {props?.flightDetailsSummary?.responseData
                        ?.airportLocations?.from?.cityName +
                        "(" +
                        props?.flightDetailsSummary?.responseData
                          ?.selectedFlight?.departure +
                        ")"}
                    </div>
                  </div>
                  <div className={styles.subAirportContainer}>
                    <span className={styles.subAirportName}>
                      {
                        props?.flightDetailsSummary?.responseData
                          ?.airportLocations?.from?.airportName
                      }
                    </span>
                    <div className={styles.terminalContainer}>
                      <TGIcon icon="circle-icon" fillColor="" />
                      <span className={styles.terminalText}>
                        {t("label_redemption_termainal")}{" "}
                        {
                          props?.flightDetailsSummary?.responseData
                            ?.selectedFlight?.departureTerminal
                        }
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.hoursContainer}>
                  {moment
                    .utc(
                      moment(
                        props?.flightDetailsSummary?.responseData
                          ?.selectedFlight?.arrivalTime,
                        "HHmm"
                      ).diff(
                        moment(
                          props?.flightDetailsSummary?.responseData
                            ?.selectedFlight?.departureTime,
                          "HHmm"
                        )
                      )
                    )
                    .format("h [h] m [min]")}
                </div>
                <div className={styles.toCityContainer}>
                  <div className={styles.airportTimeName}>
                    <div className={styles.timeContainer}>
                      <span className={styles.time}>
                        {moment(
                          props?.flightDetailsSummary?.responseData
                            ?.selectedFlight?.arrivalTime,
                          "HHmm"
                        ).format("hh:mm")}
                      </span>
                    </div>
                    <div className={styles.airportName}>
                      {props?.flightDetailsSummary?.responseData
                        ?.airportLocations?.to?.cityName +
                        "(" +
                        props?.flightDetailsSummary?.responseData
                          ?.selectedFlight?.arrival +
                        ")"}
                    </div>
                  </div>
                  <div className={styles.subAirportContainer}>
                    <span className={styles.subAirportName}>
                      {
                        props?.flightDetailsSummary?.responseData
                          ?.airportLocations?.to?.airportName
                      }
                    </span>
                    <div className={styles.terminalContainer}>
                      <TGIcon icon="circle-icon" fillColor="" />
                      <span className={styles.terminalText}>
                        {t("label_redemption_termainal")}{" "}
                        {
                          props?.flightDetailsSummary?.responseData
                            ?.selectedFlight?.arrivalTerminal
                        }
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.airCraftContainer}>
                  <div className={styles.aircraftHeader}>
                    <TGIcon icon="star-alliance-icon" fillColor="" />
                    <span className={styles.thaiAirwaysText}>
                      {t("label_redemption_thai_airways_radio")}
                    </span>
                    <TGIcon icon="circle-icon" fillColor="" />
                    <span className={styles.tgFlightNumber}>
                      {props?.flightDetailsSummary?.responseData?.mc}
                      {props?.flightDetailsSummary?.responseData?.flightNum}
                    </span>
                  </div>
                  <div className={styles.airbusName}>
                    {
                      props?.flightDetailsSummary?.responseData?.selectedFlight
                        ?.aircraftTypeDesc
                    }
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </TGModal>
    </>
  );
};

export default FlightDetails;
