import React, { useState, useEffect } from "react";
import TGModal from "../../../../../../shared/tg-modal";
import TGIcon from "../../../../../../shared/tg-icon";
import styles from "./no-seats-available-popup.module.scss";
import { useTranslation } from "react-i18next";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { useClassObserver } from "../../../../../../utils/hook/useClassObserver";

interface IProps {
  hidePanel: () => void;
  notEnoughMessage: boolean;
}
const NoFlightsAvailablePopUp = (props: IProps) => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(true);
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const isMobile = useScreenSize()?.deviceSize?.width < 768;

  const isDesktopModalExists = useClassObserver("desktop-modal");
  useEffect(() => {
    if (!isMobile) {
      if (isDesktopModalExists) {
        document.documentElement.style.setProperty("--html-width", "100%");
      }
    }
  }, [isDesktopModalExists, isMobile]);

  return (
    <>
      <TGModal
        show={show}
        handleClose={props.hidePanel}
        buttonLabel="OK"
        customModalClass={styles.customModalClass}
        customOffCanvasClass={styles.customOffCanvasClass}
        centered={true}
        backdrop="static"
      >
        <div className={styles.tcModalFlex}>
          <div className={styles.tcModal}>
            <TGIcon fillColor={"none"} icon="not-enough-seats" />
            <div className={styles.modalContent}>
              {props.notEnoughMessage ? (
                <span className={styles.modalHeading}>
                  {t("label_not_enough_seats")}
                </span>
              ) : (
                <span className={styles.modalHeading}>
                  {t("label_seats_not_available_for_selected_class")}
                </span>
              )}
              <span className={styles.modalDescription}>
                
                {t("label_please_choose_a_different_date")}
              </span>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {isDesktopView && (
              <TGButtonVariants
                label="Change class from Previous Flight"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                bgColor="var(--primary-surface-weak, #F5EFFF)"
                bgColorHover="var(--primary-surface-weak, #F5EFFF)"
                padding="12px 16px"
                textColor="var(--primary-text, #684B9B)"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                height="50px"
                width={!isDesktopView ? "" : "42%"}
                onClick={props.hidePanel}
              />
            )}
            <TGButtonVariants
              label="Stay to Change Flight"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="30px"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
              bgColor="#381B6B"
              bgColorHover="#3D2D5E"
              padding="12px 16px"
              textColor="#FFFFFF"
              fontFamily="Inter"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="160%"
              height="50px"
              width={!isDesktopView ? "" : "32%"}
              onClick={props.hidePanel}
            />
            {!isDesktopView && (
              <TGButtonVariants
                label="Change Class from Previous Flight"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                bgColor="var(--primary-surface-weak, #F5EFFF)"
                bgColorHover="var(--primary-surface-weak, #F5EFFF)"
                padding="12px 16px"
                textColor="var(--primary-text, #684B9B)"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                height="50px"
                width={!isDesktopView ? "" : "42%"}
                onClick={props.hidePanel}
              />
            )}
          </div>
        </div>
      </TGModal>
    </>
  );
};

export default NoFlightsAvailablePopUp;
